import React, { useState } from "react";
import './index.css';
import sssLogo from "../Assets/SSS_Logo.png";
import download from "../Assets/download.png";
import arrow from "../Assets/arrow.png";

import icon1 from "../Assets/icon1.png";
import placeholder1 from "../Assets/placeholder1.jpg";
import placeholder2 from "../Assets/placeholder2.jpg";
import placeholder3 from "../Assets/placeholder3.jpg";
import placeholder4 from "../Assets/placeholder4.jpg";
import placeholder5 from "../Assets/placeholder5.jpg";
import placeholder6 from "../Assets/placeholder6.jpg";
import placeholder7 from "../Assets/placeholder7.jpg";

import { BrowserRouter as Router, Link, Routes, Route } from 'react-router-dom';
import { Footer } from 'flowbite-react';
import { LightningBoltIcon, CalendarIcon } from '@heroicons/react/outline';
import { FaHome } from "react-icons/fa";


const LazyLoadedImage = ({ src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="lazy-image-container">
      <img
        src={src}
        alt={alt}
        className={`${className} lazy-image ${isLoaded ? 'loaded' : ''}`}
        loading="lazy"
        onLoad={handleImageLoad}
      />
    </div>
  );
};
 
function HomePage() {

  return (
    
    <div className="bg-gray-100" style={{ backgroundColor: 'black'}}>
      <img src={arrow} className="logo-2 shadow-lg rounded" width="45sspx" alt="Stanford Startup Society Logo" />
      <header className="flex mx-auto">
        
        <div className="logo">
          <img src={sssLogo} className="logo-2 shadow-lg rounded" width="45sspx" alt="Stanford Startup Society Logo" />
          <a href="#home" className="stanford-text"> <span style={{ paddingRight: "5px"}}>Stanford</span>Startup Society </a> 
          
          </div>
        <nav className="aaa">
          <ul className="flex justify-between">
          <a href="#who"> <li className="li-item">Culture</li></a>
          <a href="#what"><li className="li-item"> About </li></a>
          <a href="#where"> <li className="li-item"> Location </li></a>
            <li className="li-button"> <a href="https://lu.ma/startupsociety" target="_blank" rel="noopener noreferrer" className="signup-button">Join Us</a>
</li>
          </ul>
        </nav>
      </header>

      <main>
        <div>
        <section className="hero text-white pt-14" style={{ backgroundColor: '#29292c' }} id="home">
          <div className="mx-auto"> 
            <div className="flex flex-row mt-5 xl:flex-row l:flex-row md:flex-col">
              <div className="flex justify-center items-center"> 
                <div className="mt-5 items-center ml-14"> 
                  <img src={download} className="logo-3 shadow-lg rounded" alt="Stanford Startup Society Logo" />
                </div>

                <div className="mt-14 mb-10">
                  <div className="work text-center"><span style={{color: '#d82c43', fontWeight: 'bolder'}}>Stanford</span> Startup Society</div>
                  <div className="main-text-for-work"></div>
                    <h2>Discover the Stanford Startup Society—a vibrant community at Stanford University!</h2>
                  <button className="rsvp-button">RSVP Today!</button>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center mt-10">
</div>

            <div className="flex flex-wrap justify-center pb-14">
            <a href="https://lu.ma/startupsociety" target="_blank" rel="noopener noreferrer">
  
</a>
            </div>
          </div>
          <section style={{ backgroundColor: '#29292c', marginBottom: -30, padding: 30 }}> <hr className="" ></hr></section>
        </section>

          <section className="collage-background" style={{ backgroundColor: '#29292c', padding: 0 }}>
            <div className="text-container pt-12 pb-9">
              <h1 className="culture-text">Startup<span>Culture</span> Lives Here</h1>
              <p>Building right now</p>
            </div>
            <hr className="" />
        <div className="text-container pt-12">
        </div>

        <div className="image-collage-container" style={{ backgroundColor: '#29292c'}}>
                <div>
                  <LazyLoadedImage src={placeholder1} alt="Placeholder 1" className="collage-image" />
                  <LazyLoadedImage src={placeholder7} alt="Placeholder 6" className="collage-image" />
                </div>
                <div>
                  <LazyLoadedImage src={placeholder2} alt="Placeholder 2" className="collage-image" />
                  <LazyLoadedImage src={placeholder3} alt="Placeholder 3" className="collage-image" />
                  <LazyLoadedImage src={placeholder4} alt="Placeholder 4" className="collage-image" />
                </div>
                <div>
                  <LazyLoadedImage src={placeholder5} alt="Placeholder 5" className="collage-image" />
                  <LazyLoadedImage src={placeholder6} alt="Placeholder 6" className="collage-image" />
                </div>
              </div>
              <section style={{ backgroundColor: '#29292c', marginBottom: -30, padding: 30 }}>
                <hr className="" />
              </section>
              </section>


<hr className=""  id = "who"></hr>
          <section className="bg-gray-100" style={{ backgroundColor: '#29292c', margin: 0, padding: 0 }}>
            <div className="pt-8">
              <div className="text-center" style={{ color: '#EEF2F3' }}>
              <h1 className="relative inline-block group text-black text-[#120B0E]"style={{ color: '#EEF2F3' }}>
  What is the Stanford Startup Society?
  {/* Line below doesn't look that good on the website and over-complicates thing so I commented it out*/}
  {/*<span className="absolute inset-x-2 bottom-0 h-1 bg-red-700 w-full transform scale-x-2 group-hover:scale-x-100 transition-transform" style={{ color: '#B83A4B' }}></span>*/}
</h1>

<p className="text-lg pt-7 text-center text-[#120B0E]">
  Our society is a dynamic hub for <span className="text-center">Stanford’s most passionate students</span>, bold enough to pursue projects of all backgrounds, from startups and research to art and film.
  </p>                
  <p className="text-2xl pt-7 relative font-bold" style={{ color: '#120B0E' }}>
</p>
<h1>
<span className="pr-6 text-gray-100">
  Join today, and begin your <br />entrepreneurial journey with the best community at <br />
  <span id="what" style={{ color: "#990012" }}>Stanford University</span>.
</span>
</h1>
</div>

<div className="grid gap-8 items-start justify-center pt-14 pb-8">
<button
  className="relative overflow-hidden outline-none focus:outline-none transition-all duration-300 ease-in-out transform hover:bg-teal-800"
  style={{ boxShadow: '0px 0px 15px 2px rgba(255, 255, 255, 1)' }}
  onClick={() => window.open('https://lu.ma/startupsociety', '_blank')}
>
  <span className="relative px-10 z-10 inline-block py-6 text-black text-center font-semibold rounded bg-gradient-to-br from-white to-gray-200 ring ring-white ring-opacity-20">
    RSVP to build a session today!
  </span>
  <span className="absolute top-0 left-0 w-full h-full bg-B83A4B mix-blend-overlay animate-pulse rounded-full"></span>
</button>



         </div>
              </div>

            <div className="flex flex-row pt-11">
            
            <div className="container mx-20 text-black p-10 flex-1 rounded-xl mb-8 shadow-md bg-white">
  <LightningBoltIcon className="w-6 h-6 mr-2 text-gray-500 mb-5" style={{color:"#007251"}} aria-hidden="true" />
  <h2 className="font-bold">Our Build-First Philosophy</h2>
  <p className="pt-4">At the heart of our society is a simple yet powerful ethos: prioritize building above all else. In the bustling environment of Stanford, we recognize the value of our members’ time. <br></br><br></br>Our aim is to maximize time for hands-on creation, while minimizing the distractions and time-consuming formalities often associated with traditional entrepreneurship groups.</p>
</div>


<div className="container mx-20 text-black p-10 flex-1 rounded-xl mb-8 shadow-md bg-white">
  <CalendarIcon className="w-6 h-6 mr-2 text-gray-500 mb-4" style={{color:"#007251"}} aria-hidden="true" />
  <h2 className="font-bold">Weekly Sessions</h2>
  <p className="pt-4">We focus on creating a ‘hackathon-style’ atmosphere – energetic, focused, collaborative, and most importantly, fun. This will be in the form of weekly in-person sessions where you can work on any idea you’re passionate about, not just a startup.</p>
  <p> <br></br>These meet-ups are not just about discussions; they're about getting things done. Members present their ongoing projects, seek feedback, and engage in productive build sessions. Accompanied by free food and good company, these sessions are designed to be as enjoyable as they are productive.</p>
</div>

</div>
          </section>
          
          
          <section className="bg-gray-100 py-16" style={{ backgroundColor: '#29292c', color: '#EEF2F3'}} id="where">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8">When Can You Join Us?</h2>

        <div className="flex flex-wrap justify-center items-center">
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h3 className="text-2xl font-semibold mb-4" style={{ color: '#111113'}}>Sundays @ 12:30-5 p.m. </h3>
              <p className="text-gray-700 text-xl">Location: Toyon Hall </p>
            </div>
          </div>
          </div>
          </div>

          <div className="container mx-auto pt-5">
  <h2 className="text-3xl font-bold text-center mb-8 pt-4">Our Location</h2>

  <div className="flex items-center justify-center">
  <iframe
    title="Google Map"
    width="700px"
    height="500px"
    style={{ border: '5px solid #007251' }} 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.468837953248!2d-122.16604288818003!3d37.42602777195889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbad760828747%3A0x6c77fde5f2b47fbc!2sToyon%20Hall!5e0!3m2!1sen!2sus!4v1707038786495!5m2!1sen!2sus"
    allowFullScreen
  ></iframe>
</div>

</div>
          </section>

        </div>
      </main>

      {/* <footer className="bg-black min-h-[450px] md:h-[250px] text-white">
  <div className="container mx-auto">
    <div className="flex flex-col md:flex-row md:justify-between items-center gap-y-5 py-18 md:py-10">
      <div className="flex gap-1 items-center text-xl md:text-2xl lg:text-3xl font-bold">
    <div className="footer-header"> Stanford Startup Society 2024© </div>
    </div>
    <div className="footer-sections">
    <div className="flex flex-col gap-8 md:flex-row tracking-wider text-gray-400 text-center">
    <div className="hover:text-white cursor-pointer"> About</div>
    <div className="hover:text-white cursor-pointer"> LinkedIn</div>
    <div className="hover:text-white cursor-pointer"> Contact</div>
    </div>
    </div>

    </div>
  </div>
</footer> */}

<footer className="bg-black text-white mx-10">
  <div className="container mx-auto py-8 md:py-12 lg:py-9">
    <div className="flex flex-col md:flex-row md:justify-between items-center gap-y-5">
      <div className="text-xl md:text-2xl lg:text-3xl font-bold">
        <span style={{ color: '#82212F' }}>Stanford</span> Startup Society
      </div>
      <div className="flex gap-8 md:flex-row tracking-wider text-gray-400 text-center md:mt-0">
        <div className="hover:text-white cursor-pointer"><a href="https://docs.google.com/document/d/1yKWXuOS0yrnqEdXNVXiQ4wWpPJrQp7MSOKnLmGeKTao/edit" target="_blank" rel="noopener noreferrer">About</a></div>
        <div className="hover:text-white cursor-pointer"><a href="https://www.linkedin.com/company/stanford-startup-society/" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
        <div className="hover:text-white cursor-pointer">Contact</div>
      </div>
    </div>
  </div>
</footer>

    </div>
  );
}

export default HomePage;
